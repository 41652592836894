<template>
  <div
    :class="['lead', 'lead-modal', {
      'lead-variant-1': lead1ModalState,
      'lead-variant-2': lead2ModalState,
      'lead-variant-3': lead3ModalState,
      'lead-variant-4': lead4ModalState,
      'lead-variant-5': lead5ModalState,
      'lead-variant-6': lead6ModalState,
      'lead-variant-7': lead7ModalState,
      'lead-variant-8': lead8ModalState,
      'lead-variant-9': lead9ModalState,
      'lead-variant-2': lead10ModalState,
      'lead-variant-2': lead12ModalState,
      'lead-variant-1': lead13ModalState,
      'lead-variant-1': lead14ModalState,
      'lead-variant-1': lead15ModalState,
    }]"
  >
    <div class="lead__inner">
      <button
        class="lead__close m-btn"
        @click="closeLeadModal"
      >
        <img
          src="/v2/modal/close.svg"
          alt="cross"
          class="lead__close-icon"
        >
      </button>

      <img
        v-if="!lead8ModalState && !lead9ModalState && !haveVerticalOverflow"
        class="lead__heart-img"
        src="/v2/modal/heart.svg"
        alt="heart"
      >

      <div
        class="lead__form"
        @keypress.enter="getModalData.action"
      >
        <h2
          class="lead__title"
          v-html="getModalData.title"
        />

        <h5
          v-if="getModalData.subtitle"
          class="lead__subtitle"
          v-html="getModalData.subtitle"
        />

        <form-name-field
          ref="nameField"
          v-model="nameInput"
          class="form__input"
          placeholder="Имя"
          :round-border="lead5ModalState || lead6ModalState"
        />

        <form-phone-field
          ref="phoneField"
          class="form__input form__input_phone"
          :placeholder="needParentPhone ? 'Телефон родителя' : 'Телефон'"
          :errors-list="submitError"
          :error="validForm === false"
          :round-border="lead5ModalState || lead6ModalState || lead7ModalState"
          @updatePhone="phoneInput = $event"
        />

        <form-phone-field
          v-if="needParentPhone"
          ref="phoneCommentField"
          class="form__input form__input_phone"
          placeholder="Телефон ученика"
          :errors-list="submitError"
          :error="validForm === false"
          :round-border="lead5ModalState || lead6ModalState || lead7ModalState"
          @updatePhone="phoneCommentInput = $event"
          @clearSubmitError="clearSubmitError"
        />

        <form-email-field
          ref="emailField"
          v-model="emailInput"
          class="form__input form__input_email"
          :submit-error="submitError"
          :round-border="lead5ModalState || lead6ModalState"
          placeholder="Почта"
          @clearSubmitError="clearSubmitError"
        />

        <form-text-field
          v-if="partnersSchool"
          v-model="comment"
          class="form__input"
          placeholder="Промокод"
        />

        <div class="lead__form_submit m-btn__pull">
          <button
            class="m-btn"
            @click="getModalData.action"
          >
            {{ getModalData.buttonText }}
          </button>
        </div>

        <span class="lead__form_privacy">
          Нажимая на кнопку, вы соглашаетесь
          <button
            v-if="forCom"
            class="m-btn"
            @click="closeLeadModal(); $root.$emit('comPrivacy')"
          >
            с условиями обработки данных
          </button>
          <nuxt-link
            v-else
            to="/privacy"
            target="_blank"
          >
            с условиями обработки данных
          </nuxt-link>
        </span>

        <template v-if="!cenyPage && !lead12ModalState && !lead15ModalState">
          <p
            v-if="
              lead5ModalState
                || lead6ModalState
                || lead7ModalState
                || lead8ModalState
                || lead9ModalState
            "
            class="lead__tx"
          >
            Или напишите нам в чат:
          </p>
          <p
            v-else
            class="lead__tx"
          >
            Остались вопросы?  <br> Пишите нам в чат:
          </p>
          <div class="contacts__row-bottom">
            <a
              class="contacts__link"
              href="https://wa.me/79686286327"
              target="_blank"
              rel="nofollow"
            >
              <img
                class="contacts__soc-icon"
                src="/v2/modal/whatsapp.svg"
                alt="whatsapp"
              >
            </a>

            <a
              class="contacts__link"
              href="https://t.me/schoolsynergy_bot"
              target="_blank"
              rel="nofollow"
            >
              <img
                class="contacts__soc-icon"
                src="/v2/modal/telegram.svg"
                alt="telegram"
              >
            </a>

            <a
              class="contacts__link"
              href="mailto:nstolbova@synergy.ru"
              target="_blank"
              rel="nofollow"
            >
              <img
                class="contacts__soc-icon"
                src="/v2/modal/email.svg"
                alt="email"
              >
            </a>
          </div>
        </template>
      </div>

      <img
        v-if="lead8ModalState || lead9ModalState"
        src="/v2/modal/last-group/sticker-left.svg"
        alt="sticker"
        class="lead__sticker lead__sticker-left"
      >

      <img
        v-if="lead8ModalState || lead9ModalState"
        src="/v2/modal/last-group/sticker-right.svg"
        alt="sticker"
        class="lead__sticker lead__sticker-right"
      >
    </div>

    <div
      class="lead__overlay"
      @click="closeLeadModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FormNameField from '@/components/v2/common/fileds/FormNameField.vue';
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';
import FormPhoneField from '@/components/v2/common/fileds/FormPhoneField.vue';
import sendLeadMixinConstructor from '@/mixins/sendLeadMixinConstructor';
import { getQuestionnairePath } from '@/utils/questionnairePath';
import FormTextField from '../common/fileds/FormTextField.vue';

const sendLeadMixin = sendLeadMixinConstructor({
  needPhone: true,
  needEmail: true,
  needToSendTitle: true,
  needToSendComments: true,
});

export default {
  name: 'LeadModal',
  components: {
    FormTextField,
    FormPhoneField,
    FormEmailField,
    FormNameField,
  },
  mixins: [sendLeadMixin],

  props: {
    landCode: {
      type: String,
      default: null,
    },
    forCom: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    comment: '',
    haveVerticalOverflow: false,
  }),

  computed: {
    ...mapGetters({
      isLaptop: 'isLaptop',
    }),
    ...mapGetters('modals', {
      leadTitle: 'leadTitle',
      lead1ModalState: 'lead1Modal',
      lead2ModalState: 'lead2Modal',
      lead3ModalState: 'lead3Modal',
      lead4ModalState: 'lead4Modal',
      lead5ModalState: 'lead5Modal',
      lead6ModalState: 'lead6Modal',
      lead7ModalState: 'lead7Modal',
      lead8ModalState: 'lead8Modal',
      lead9ModalState: 'lead9Modal',
      lead10ModalState: 'lead10Modal',
      lead11ModalState: 'lead11Modal',
      lead12ModalState: 'lead12Modal',
      lead13ModalState: 'lead13Modal',
      lead14ModalState: 'lead14Modal',
      lead15ModalState: 'lead15Modal',
      openDoorsModal: 'openDoorsModal',
    }),

    needParentPhone() {
      const onlineSchoolCenyPage = this.$route.name === 'online-school-ceny';

      const modals = this.lead1ModalState
        || this.lead2ModalState
        || this.lead4ModalState
        || this.lead10ModalState
        || this.lead11ModalState
        || this.lead13ModalState;

      return modals && onlineSchoolCenyPage;
    },

    getModalData() {
      const defaultData = {
        title: '',
        subtitle: '',
        buttonText: 'Отправить',
        action: this.submit,
      };

      if (this.lead1ModalState) {
        return {
          ...defaultData,
          title: 'Получите <span><span>бесплатный</span></span> доступ к&nbsp;платформе на&nbsp;7&nbsp;дней',
          action: this.partnersSchool ? this.submit : this.signUp,
        };
      }
      if (this.lead2ModalState) {
        return {
          ...defaultData,
          title: 'Запишитесь на <span><span>бесплатную</span></span> консультацию',
          subtitle: 'Наш специалист свяжется с&nbsp;вами и&nbsp;ответит на&nbsp;любые вопросы',
        };
      }
      if (this.lead3ModalState) {
        return {
          ...defaultData,
          title: 'Записаться на <span><span>бесплатный</span></span> пробный урок и&nbsp;диагностику',
          buttonText: 'Получить доступ',
        };
      }
      if (this.lead4ModalState) {
        return {
          ...defaultData,
          title: 'Оставьте заявку и&nbsp;наш специалист ответит на все ваши вопросы',
        };
      }
      if (this.lead5ModalState) {
        return {
          ...defaultData,
          title: 'Запишитесь на <span><span>бесплатную</span></span> диагностику гибких навыков',
          subtitle: 'С вами свяжется отдел заботы и назначит удобное время',
        };
      }
      if (this.lead6ModalState) {
        return {
          ...defaultData,
          title: 'Запишитесь на курс и получите <span><span>бесплатную</span></span> диагностику гибких навыков',
        };
      }
      if (this.lead7ModalState) {
        return {
          ...defaultData,
          // eslint-disable-next-line max-len,vue/max-len
          title: 'Оставьте заявку, чтобы&nbsp;узнать про&nbsp;актуальные<span><span>скидки</span></span> <span><span>и&nbsp;бонусы</span></span>',
          subtitle: 'С вами свяжутся наши специалисты и&nbsp;назначат удобное время',
          buttonText: 'Записаться',
        };
      }
      if (this.lead8ModalState) {
        return {
          ...defaultData,
          // eslint-disable-next-line max-len,vue/max-len
          title: 'Успейте записаться, чтобы <span><span>забрать последнее</span></span> место в классе <span><span>со скидкой</span></span>',
          buttonText: 'Записаться в школу',
        };
      }
      if (this.lead9ModalState) {
        return {
          ...defaultData,
          title: 'Оставьте заявку и узнайте о текущих <span><span>скидках и акциях</span></span>',
          subtitle: 'С вами свяжется отдел заботы и подробно расскажет о всех условиях',
        };
      }
      if (this.lead10ModalState) {
        return {
          ...defaultData,
          title: 'Скачайте учебный план',
          subtitle: 'Оставьте заявку и посмотрите актуальное расписание занятий',
          buttonText: 'Скачать',
        };
      }

      if (this.lead11ModalState) {
        return {
          ...defaultData,
          title: 'Оставьте заявку и&nbsp;скачайте <br class="tablet"> «Чек-лист по переходу в&nbsp;онлайн‑школу»',
          buttonText: 'Скачать',
        };
      }
      if (this.lead12ModalState) {
        return {
          ...defaultData,
          title: 'Зарегистрируйтесь для сохранения результатов теста',
          // eslint-disable-next-line max-len,vue/max-len
          subtitle: ' ',
          buttonText: 'Перейти к профтесту',
        };
      }
      if (this.lead13ModalState) {
        return {
          ...defaultData,
          title: 'Получить бесплатный доступ <br> к платформе и 1000 урокам',
          action: this.signUp,
          buttonText: 'Получить доступ ',
        };
      }
      if (this.lead14ModalState) {
        return {
          ...defaultData,
          title: 'Получите <span><span>бесплатный доступ</span></span> на платформу к 1000 урокам',
          // eslint-disable-next-line max-len,vue/max-len
          subtitle: null,
          buttonText: 'Перейти на платформу',
        };
      }
      if (this.lead15ModalState) {
        return {
          ...defaultData,
          title: 'Узнайте <span><span>как&nbsp;прикрепиться</span></span> к&nbsp;школе и&nbsp;сдать аттестации',
          subtitle: 'Наш&nbsp;специалист свяжется с вами и&nbsp;ответит на&nbsp;любые вопросы',
        };
      }

      return defaultData;
    },

    titleToSend() {
      return this.leadTitle || this.getModalData.title.replace(/<\/?[^>]+(>|$)/g, '');
    },

    componentLandCode() {
      if (this.lead8ModalState) {
        return 'new_online_school_poslednie_mesta_banner';
      }
      return null;
    },

    commentsToSend() {
      return this.comment ? `промокод: ${this.comment}` : null;
    },

    cenyPage() {
      return this.$route.name === 'online-school-ceny' || this.$route.name === 'online-school';
    },
    cenyPoddomenPage() {
      return this.$route.name === 'online-school-ceny-poddomen';
    },
    partnersSchool() {
      return this.$route.name === 'online-school-partners';
    },
  },

  created() {
    if (this.needParentPhone) {
      this.phoneCommentInput = '';
      this.needCommentPhone = true;
    }
  },
  mounted() {
    if (this.isLaptop) {
      document.body.style.overflow = 'hidden';
    }

    const element = document.querySelector('.lead__inner');
    this.haveVerticalOverflow = element.scrollHeight > element.clientHeight
      || element.scrollWidth > element.clientWidth;
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto';
  },

  methods: {
    ...mapActions('modals', {
      closeLeadModal: 'closeLead',
    }),
    onSubmit() {
      if (this.lead10ModalState) {
        window.open(
          `${window.location.origin}/questionnaire`,
          '_blank',
        );
        window.location.replace('https://drive.google.com/file/d/1y7raz5fM-eO6hlpVitsV68a_sPjoS4HT/view');
        return;
      }

      if (this.lead11ModalState) {
        window.open(
          `${window.location.origin}/questionnaire`,
          '_blank',
        );
        if (this.cenyPage) {
          window.location.replace('https://drive.google.com/file/d/101906sGs26BtI8WOHzdvwFt4W6dtX039/view');
        } else {
          window.location.replace('https://drive.google.com/file/d/1mqj0Qh5KJiM_-D_8PjGaZdhGsGiPcBDd/view');
        }
        return;
      }

      // this.$router.push('/thanks');
      if (this.cenyPoddomenPage) {
        this.$router.push('/thanks');
        this.closeLeadModal();
        return;
      }
      this.$router.push(getQuestionnairePath(this.$route));
      this.closeLeadModal();
    },
  },
};
</script>

<style scoped lang="scss">
.lead {
  position: fixed;
  z-index: 100;
  inset: 0;
  display: grid;
  place-content: center;

  &__inner {
    position: relative;
    z-index: 2;

    margin: auto;

    width: min(610px, calc(100% - 16px));
    height: fit-content;
    max-height: 95vh;

    border-radius: 20px;
    border: 2px solid $black;
    background: $white-color;
    box-shadow: 4px 4px 0 0 $black;

    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      width: 3px !important;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px !important;
      width: 3px;
      background-color: #8d8d8d !important;
    }

    @include media-down(tablet) {
      padding-top: 16px;
      padding-bottom: 40px;
      box-shadow: 2px 4px 0 0 $black;
    }

    @include media-down(mobile) {
      overflow: hidden;
    }
  }

  &__close {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
  }

  ::v-deep .lead__title {
    margin-bottom: 40px;

    color: $black;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.2px;

    @include media-down(tablet) {
      margin-bottom: 20px;
      max-width: 410px;

      color: $black;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 100%;
    }

    & > span {
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 28px;
        background-color: #FFB379;
        position: absolute;
        left: 0;
        top: 5px;

        @include media-down(tablet) {
          height: 17px;
          top: 4px;
        }
      }

      span {
        position: relative;
        z-index: 2;
      }
    }
  }

  .lead__subtitle {
    max-width: 414px;
    color: $black;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    margin: 0 auto 40px;
  }

  .lead__form {
    position: relative;
    padding: 38px;

    @include media-down(tablet) {
      margin-top: 10px;
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;

      padding: 12px 8px;
    }

    p {
      color: $black;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 24px;
      font-weight: 500;
      line-height: 130%;
      margin-bottom: 30px;

      @include media-down(tablet) {
        text-align: center;
        margin-bottom: 24px;
        font-size: 20px;
        font-weight: 600;
        line-height: 100%;
        max-width: 330px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .form__input {
      margin-bottom: 10px;
      z-index: 2;

      @include media-down(mobile) {
        margin-bottom: 8px;
      }

      ::v-deep input:not(.phone-field__input) {
        width: 100%;
        color: $black;
        font-size: 20px;
        letter-spacing: 0.4px;
        border-radius: 15px;
        border: 2px solid $black;
        padding: 20px 18px 22px;
        height: auto;

        @include media-down(mobile) {
          padding: 16px 14px 17px;
          font-size: 18px;
          letter-spacing: 0.36px;
        }
      }
    }

    .lead__form_submit {
      margin-top: 20px;
      margin-bottom: 10px;
      border-radius: 15px;

      @include media-down(mobile) {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      button {
        padding: 19px 20px 21px;
        border-radius: 15px;
        border: 2px solid $black;
        background-color: #b6b6ff;
        color: $black;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 20px;
        font-weight: 700;
        width: 100%;
        cursor: pointer;
        position: relative;

        @include media-down(mobile) {
          padding: 15px 10px 16px;
          font-size: 18px;
        }

        &:after {
          content: '';
          width: 7px;
          height: 21px;
          display: inline-block;
          background-image: url('/v2/main/form/button-arrow.svg');
          margin-left: 5px;
        }
      }
    }

    .lead__form_privacy {
      text-align: center;
      color: $black;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 18px;
      font-weight: 600;
      line-height: 100%;

      margin-bottom: 2px;
      display: block;

      @include media-down(tablet) {
        text-align: center;
        margin-bottom: 36px;
        font-weight: 500;
      }

      button,
      a {
        font-size: 18px;
        display: inline-block;
        color: #4a88da;
      }
    }
  }

  &__tx {
    margin-top: 35px;

    text-align: center;

    color: $black;
    font-variant-numeric: lining-nums proportional-nums;

    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
  }

  &__overlay {
    position: absolute;
    z-index: 1;
    inset: 0;
    cursor: pointer;

    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(2.5px);
  }
}

.contacts {
  display: grid;

  @include media-down(tablet) {
    width: 100%;
  }

  &__top {
    display: flex;
    gap: 36px;

    margin-bottom: 40px;
  }

  &__logo-link {
    width: fit-content;

    transition: 0.2s ease-out;

    &:hover {
      opacity: 0.6;
    }
  }

  &__logo {
    display: block;

    width: 100%;

    @include media-down(tablet) {
      width: 111px;
    }
  }

  &__logo-skv {
    transition: 0.2s ease-out;

    @include media-down(tablet) {
      width: 35px;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  &__phone {
    margin-bottom: 20px;

    width: fit-content;

    font-size: 35px;
    font-weight: 600;
    color: $white-color;

    transition: 0.2s ease-out;

    @include media-down(tablet) {
      justify-self: center;

      font-size: 25px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__email {
    margin-bottom: 40px;

    width: fit-content;

    font-size: 24px;
    font-weight: 500;
    color: $white-color;

    transition: 0.2s ease-out;

    @include media-down(tablet) {
      justify-self: center;

      margin-bottom: 30px;

      font-size: 18px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__msngr {
    @include media-down(tablet) {
      display: none;
    }
  }

  &__tx {
    margin-bottom: 12px;

    font-size: 20px;
    font-weight: 500;
    color: #a7a7a7;

    br {
      display: none;

      @include media-down(tablet) {
        display: block;
      }
    }
  }

  &__row-bottom {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  &__link {
    display: block;
  }

  &__soc-icon {
    position: relative;
    width: 50px;
    height: 50px;
    border: 2px solid $black;
    background-color: $black;
    border-radius: 10px;
    transition: 0.2s ease-out;
    box-shadow: 2px 2px 0 0 $black;

    &:hover {
      transform: translate(2px, 2px);
      box-shadow: none;
    }
  }
}

.lead__heart-img {
  position: absolute;
  bottom: 0;
  right: 128px;
  height: 50px;
  width: 55px;
  object-fit: cover;
  object-position: top;

  @include media-down(mobile) {
    width: 37px;
    top: 65px;
    right: 15px;
  }
}

.lead-variant-1,
.lead-variant-3 {
  .lead__title {
    margin-bottom: 40px;
  }

  .lead__heart-img {
    position: absolute;
    bottom: 0;
    right: 115px;
    width: 55px;

    @include media-down(mobile) {
      width: 37px;
      top: 65px;
      right: 15px;
    }
  }
}

.lead-variant-2 {
  .lead__title {
    margin-bottom: 10px;
  }

  .lead__heart-img {
    position: absolute;
    top: 154px;
    right: 44px;
    width: 48px;
  }
}

.lead-variant-4 {
  .lead__heart-img {
    position: absolute;
    z-index: 2;
    top: 154px;
    right: 45px;
    width: 55px;

    @include media-down(mobile) {
      z-index: 1;
      width: 37px;
      top: 65px;
      right: 15px;
    }
  }
}

.lead-variant-5,
.lead-variant-6 {
  ::v-deep .lead__title {
    margin-bottom: 10px;

    > span {
      display: inline-block;

      &:before {
        top: auto;
        bottom: 0;
        height: 9px;
        background-color: #B3A4EE;
      }
    }
  }

  .lead__heart-img {
    position: absolute;
    top: 154px;
    right: 45px;
    width: 55px;

    @include media-down(mobile) {
      width: 37px;
      top: 65px;
      right: 15px;
    }
  }

  .lead__form .lead__form_submit button {
    border-radius: 100px;
    background-color: #9FE3FF;
  }
  .m-btn__pull {
    &:before {
      border-radius: 100px;
    }
  }

  ::v-deep .lead__form .form__input input:not(.phone-field__input) {
    border-radius: 100px;
  }
}

.lead-variant-6 {
  ::v-deep .lead__title {
    margin-bottom: 32px;
  }

  .lead__heart-img {
    position: absolute;
    top: auto;
    bottom: 55px;
    right: 75px;
    width: 55px;

    @include media-down(mobile) {
      width: 37px;
      top: 65px;
      right: 15px;
    }
  }
}

.lead-variant-7 {
  ::v-deep .lead__title {
    margin-bottom: 10px;

    & > span {
      display: inline-block;

      &:before {
        top: auto;
        bottom: 0;
        height: 9px;
        background-color: $green;
      }
    }
  }

  .lead__heart-img {
    display: none;
  }

  .lead__form {
    ::v-deep .form__input input:not(.phone-field__input) {
      border-radius: 100px;
    }

    .lead__form_submit {
      border-radius: 100px;

      button {
        background-color: #9FE3FF;
        border-radius: 100px;
      }
    }

    .lead__tx {
      margin-top: 5px;
      margin-bottom: 20px;
    }
  }
}

.lead-variant-8,
.lead-variant-9 {
  ::v-deep .lead__title {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;

    @include media-down(tablet) {
      padding: 0 20px;
      margin-bottom: 16px;
    }

    & > span {
      display: inline-block;

      &:before {
        top: auto;
        bottom: 0;
        height: 9px;
        background-color: #C972FF;
      }
    }
  }

  .lead {
    &__inner {
      background-image: url('/v2/modal/last-group/bg.png');
      background-size: cover;

      @include media-down(tablet) {
        max-width: 344px;
        padding-bottom: 24px;
      }
    }

    &__form {

      @include media-down(tablet) {
        padding-top: 0;
        margin-top: 0;
      }

      .form__input {
        @include media-down(tablet) {
          margin-bottom: 8px;
        }
      }

      ::v-deep .form__input input:not(.phone-field__input) {
        border-radius: 100px;
        border: 2px solid rgba($black-color, .1);

        @include media-down(tablet) {
          height: 58px;
          font-size: 18px;
        }
      }

      ::v-deep .form__input_phone {
        border-radius: 100px;

        .phone-field__wrap {
          border-radius: 100px;
          border: 2px solid rgba($black-color, .1);

          input {
            border-radius: 100px;

            @include media-down(tablet) {
              font-size: 18px;
            }
          }
        }
      }

      .lead__form_submit {
        border-radius: 100px;

        @include media-down(tablet) {
          margin-top: 0;

          &::before {
            display: none;
          }
        }

        button {
          background-color: #FF7233;
          border-radius: 100px;
          color: $white-color;
          padding: 21px 20px;
          font-family: 'TildaSans', sans-serif;

          @include media-down(tablet) {
            padding: 16.25px 20px;
            font-size: 18px;
            box-shadow: 2px 4px 0 0 $black-color;

            &:hover {
              transform: translate(2px, 4px);
              box-shadow: 0 0 0 0 $black-color;
            }
          }

          &:after {
            background-image: url('/v2/modal/last-group/arrow.svg');
            background-repeat: no-repeat;
            height: 12px;
          }
        }
      }

      .lead__tx {
        margin-top: 36px;
        margin-bottom: 20px;

        @include media-down(tablet) {
          margin-top: 28px;
          font-size: 19px;
        }
      }

      .lead__form_privacy {
        @include media-down(tablet) {
          font-size: 16px;
          margin-bottom: 28px;
        }

        a {
          color: #B3A4EE;
        }
      }
    }

    &__sticker {
      position: absolute;
      bottom: 0;

      &-left {
        left: 0;

        @include media-down(tablet) {
          max-width: 80px;
        }
      }

      &-right {
        right: 0;

        @include media-down(tablet) {
          max-width: 56px;
        }
      }
    }
  }
}

.lead-variant-9 {
  ::v-deep .lead__title {
    max-width: 510px;
    margin-bottom: 10px;

    @include media-down(tablet) {
      margin-bottom: 8px;
    }
  }

  .lead__subtitle {
    @include media-down(tablet) {
      margin-bottom: 12px;

      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0;
      text-align: center;
    }
  }
}

.lead-variant-11 {
  .lead__heart-img {
    position: absolute;
    top: 162px;
    z-index: 2;
    right: 44px;
    width: 48px;

    @include media-down(tablet) {
      display: none;
    }
  }
}
</style>
